import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';


const EnglishWritingServicePage = () => (
  <Layout
    pageWrapperClassName="english-writing-service-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Custom English Essay Writing Service: Best Choice for Every Student | TakeAwayEssay.com "
      meta={[
        {
          name: 'description',
          content: 'A custom English essay writing service can help you get free time and still be good at studies. We offer all kinds of papers for A-level to Master’s for a cheap price. Check out all the benefits you can get by using our writing service!',
        },

        {
          property: 'og:title',
          content: 'Custom English Essay Writing Service: Best Choice for Every Student | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content: 'A custom English essay writing service can help you get free time and still be good at studies. We offer all kinds of papers for A-level to Master’s for a cheap price. Check out all the benefits you can get by using our writing service!',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/' },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        True English refinement
        <br className="first-screen-section__title__br" />
        {' '}
        in essay writing
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        We proofread and edit every single essay
        <br className="first-screen-section__heading__br" />
        {' '}
        we write with concentrated attention
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        English essay writing service:
        <br />
        {' '}
        cheap papers of the best quality for your grade
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            Many students are reluctant when it comes to using a custom English essay writing
            service because most of them promise an A+. However, getting a paper with a promised
            grade is a tricky thing. First of all, there is no writing service that can guarantee
            you a grade, as there are teachers that you can’t influence. Another reason is that most
            teachers understand their students’ capabilities and as not all people are on the
            A-list.
          </p>
          <p>
            <strong>
              Here at TakeAwayEssay, you can choose what grade you want for the paper we’re going to
              help you with, and if you need a B or a C, we’ll provide you assistance with that.
              Such an approach means that you get help with your term papers without any suspicion.
            </strong>
          </p>
          <h2 className="h3">Why should I buy English essay papers from your writing Service?</h2>
          <p>
            Our custom English essay writing service offers a wide range of academic papers. We can
            write anything from an A-level essay or college research paper to a custom dissertation
            of top quality. On this website, you can get help with urgent papers with 8-hour and
            even 4-hour deadlines.
          </p>
          <p>Besides, we have a solid set of safety guarantees:</p>
          <ul className="dot-list">
            <li>
              <p>Quality assurance.</p>
              <p>
                We chose to hire a separate team to check the quality of every finished order. They
                assess the style of writing, structure, contents, reference list, format, and much
                more.
              </p>
            </li>
            <li>
              <p>Money-back guarantee.</p>
              <p>
                Our service does its best to provide you with the most suitable purchase of custom
                English essays. However, if something goes off-road, you can apply for a refund. We
                value your money’s worth and won’t hesitate to return the needed cost.
              </p>
            </li>
            <li>
              <p>Plagiarism-free guarantee.</p>
              <p>
                The first guarantee our English essay writing service has given to the first
                customers back in 2018 was the non-plagiarism one. Our professional team never
                delivers a paper without checking it with specialized software.
              </p>
            </li>
            <li>
              <p>Privacy policy.</p>
              <p>
                We get a lot of private information every day, and all of it remains fully
                confidential within our database. When you buy English essay online from us, read
                all the information about the storage and usage of your data on the privacy policy
                page.
              </p>
            </li>
          </ul>
          <h2 className="h3">Who will write my English essay? Who are your professional writers?</h2>
          <p>Here’s why we are 100% sure our writers are trustworthy:</p>
          <ul className="dot-list">
            <li>
              <p>Tests.</p>
              <p>
                Before hiring someone for our custom English essay writing service, we test the
                candidates. They have to show us their English knowledge, how good they are at their
                major, and how fast they can write.
              </p>
            </li>
            <li>
              <p>Monthly checks.</p>
              <p>
                To make sure you buy English essay online of top rated quality, every helper gets a
                special check once a month. This allows the writers to grow.
              </p>
            </li>
            <li>
              <p>Supervision.</p>
              <p>
                For the first couple of orders, our fast experts have all the support they need to
                get used to the services of the website.
              </p>
            </li>
          </ul>
          <h2 className="h3">How much do I have to pay for English essay from your writing service?</h2>
          <p>We offer:</p>
          <ul className="dot-list">
            <li>High school essays starting at $10</li>
            <li>Undergraduate papers starting at $20</li>
            <li>Master's papers starting at $25</li>
          </ul>
          <p>
            If it’s more convenient for you, change the currency on the top of the Pricing page to
            see the prices in USD. We do our best to keep the rates affordable so anyone can get a
            paid assignment. To find out just how cheap you can get an answer to your “do my English
            essay” request, use the price calculator available on the main page.
          </p>
          <p>
            To make your “do my paper for me online” request even more affordable, we provide some
            free features as an everlasting sale:
          </p>
          <ul className="dot-list">
            <li>Revisions (if applied within 7 days after the deadline)</li>
            <li>Formatting</li>
            <li>Title page</li>
            <li>Bibliography page</li>
            <li>Samples</li>
          </ul>
          <p>If you have some extra money, you can buy one or more of the additional services:</p>
          <ul className="dot-list">
            <li>
              <p>Copies of sources</p>
              <p>
                When you buy English essay online from us, get a selection of peer-reviewed articles
                and books your paper was based on for only 15$.
              </p>
            </li>
          </ul>
          <p>
            We are a legit writing service that aims at your comfort and loyalty. So, if you have
            anything else you’d love to know about us before making the final choice, we’ll be happy
            to get you the needed answers. Write us a message using Live Chat or via email and we’ll
            get back to you shortly. And if you already have all the answers, hurry up and send us
            your “write my English essay” request!
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Hire our writers">
      You don’t trust us?
      <br />
      {' '}
      Check out our service  for yourself.
    </CTA>

  </Layout>
);

export default EnglishWritingServicePage;
